body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: #ffffff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html{
  height: 100%;
  width: 100%;
}

#root{
  height: 100%;
  background-color: #ffffff;
}

.machineBoldFont{
  font-family: 'machineBoldFont', sans-serif !important;
}

@font-face {
  font-family: machineBoldFont;
  src: url('fonts/machinestd-bold.otf');
}

.machineFont{
  font-family: 'machineFont', sans-serif !important;
}

@font-face {
  font-family: machineFont;
  src: url('fonts/Machine Regular.ttf');
}

.BebasFont{
  font-family: 'BebasFont', sans-serif !important;
}

@font-face {
  font-family: BebasFont;
  src: url('fonts/BebasNeue-Regular.ttf');
}

.Calibri{
  font-family: 'Calibri', sans-serif !important;
}

@font-face {
  font-family: Calibri;
  src: url('fonts/calibril.ttf');
}

.footer-container{
  background-color: black;
  color: #ffffff;
  font-family: 'Calibri', sans-serif !important;
}

.footer-panel{
  padding: 20px;
}


