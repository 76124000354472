.imageCropContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 3rem;
    gap: 1rem;
    border-bottom: dashed 0.3rem;
    padding-bottom: 3rem;
}

.reactCrop {
    border-radius: 0.5rem;
    border: dashed 1px black;
}

.reactCanvasCropContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.reactCanvasCrop {
    border-radius: 0.5rem;
    border: dashed 1px black;
}

.cropImagesRoot {
    border: dashed 1px #ff9b01;
    text-align: center;
    border-radius: 4px;
}

.buttonContainer {
    height: 250px;
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
}

.cropImagesInput {
    visibility: hidden;
    position: absolute;
}

.addAPhotoIcon {
    height: 60px !important;
    width: 60px !important;
    color: #ff9b01 !important;
}

.imageCropCounter {
    font-size: 3rem;
}

@media screen and (max-width: 960px) {
    
    .imageCropContainer {
        grid-template-columns: 1fr;
    }

}