.titleLabelDark {
    width: fit-content;
    padding: 0rem 2rem;
    margin: 2rem auto;
    height: 4rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #000000;
    color: #ff9b01;
    text-transform: uppercase;
    border-radius: 18px;
    font-family: BebasFont, serif !important;
    font-size: 3rem;
}

.titleLabelLight {
    width: fit-content;
    padding: 0rem 2rem;
    margin: 2rem auto;
    height: 4rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #ff9b01;
    color: #000000;
    text-transform: uppercase;
    border-radius: 18px;
    font-family: BebasFont, serif !important;
    font-size: 3rem;
}

.titleLabelLink {
    cursor: pointer;
}

@media screen and (max-width: 600px){

    .titleLabelDark {
        width: fit-content;
        padding: 0rem 2rem;
        height: 3rem;
        font-size: 2rem;
    }

    .titleLabelLight {
        width: fit-content;
        padding: 0rem 2rem;
        height: 3rem;
        font-size: 2rem;
    }
    
}