

.loading-content-container{
    position: relative;
    height: 100px;
    width: 100px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 50% !important;
  }
  
  .loading-logo-img{
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 5px;
    background-color: transparent;
  }
  
  .loading-circle-img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: #ff9b01 !important;
    z-index: 1;
  }
  
  .loading-dialog-content{
    padding: 0 !important;
    overflow: hidden;
    overflow-y: hidden !important;
  }
  
  .loading-background-paper{
    background-color: #fff0 !important;
    box-shadow: none !important;
  }
  
  .loading-text{
    padding: 30px;
    color: #ff9b01;
    font-weight: bold;
    text-align: center;
  }
  
  .loading-text span{
    background-color: #ffffff;
    border: solid 7px #ff9b01;
    border-radius: 0px;
    padding: 5px;
  }