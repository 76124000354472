.homeFooterImageContainer {
    text-align: center;
}

.homeFooterMachineImageContainer {
    text-align: center;
}

.homeFooterMachineImage {
    background-color: #000000;
    border-radius: 50%;
    padding: 1rem;
    width: 50%;
}

.homeFooterImage {
    width: 50%;
}

.homeFooterCenterContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeFooterCenter {
    width: 100%;
    text-align: center;
}

@media screen and (max-width: 960px){

    .homeFooterMachineImage {
        width: 40%;
    }
    
}