.TMPButtonDisabled {
    cursor: not-allowed !important;
    color: #666666 !important;
}

.TMPButtonSmallDark {
    width: fit-content;
    margin: 2rem auto;
    padding: 0.5rem 1rem;
    height: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #000000;
    color: #ff9b01;
    text-transform: uppercase;
    border-radius: 1rem;
    font-family: BebasFont, serif !important;
    font-size: 1.5rem;
}

.TMPButtonDark {
    width: 50%;
    margin: 2rem auto;
    height: 4rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #000000;
    color: #ff9b01;
    text-transform: uppercase;
    border-radius: 1rem;
    font-family: BebasFont, serif !important;
    font-size: 3rem;
}

.TMPButtonLight {
    width: 50%;
    margin: 2rem auto;
    height: 4rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #ff9b01;
    color: #000000;
    text-transform: uppercase;
    border-radius: 1rem;
    font-family: BebasFont, serif !important;
    font-size: 3rem;
}

.TMPButtonLink {
    cursor: pointer;
}

@media screen and (max-width: 600px){

    .TMPButtonDark {
        width: 90%;
        height: 3rem;
        font-size: 2rem;
    }

    .TMPButtonLight {
        width: 90%;
        height: 3rem;
        font-size: 2rem;
    }
    
}