.how-to-publish-root {
    text-align: center;
    justify-content: center;
    border-bottom: solid 1px #bdbdbd;
    padding-bottom: 2rem;
}

.how-to-publish-download-pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: black;
}

.how-to-publish-download-pdf > svg {
    font-size: 5rem;
    color: #ff4040;
    margin-bottom: 1rem;
}

.how-to-publish-download-pdf > span {
    display: flex;
    align-items: center;
    border-bottom: solid 2px black;
}

.how-to-publish-body {
    display: flex;
    justify-content: center;
    gap: 2rem;
}