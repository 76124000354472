.yellowBackground{
    background-color: #ff9b30;
}

.mainPaperCotizar{
    text-align: center;
    border-radius: 0px !important;
    background-color: unset !important;
}

.cotizarFormContainer{
    width: 100% !important;
    margin: 0px !important;
}

.cotizarTitle{
    font-family: BebasFont, serif !important;
    padding-top: 20px;
    width: 100%;
}

.cotizacionPanelButton{
    font-family: BebasFont, serif !important;
    border-radius: 0px !important;
    height: 50px;
    width: 100%;
    font-size: 25px !important;
    color: #000000 !important;
    background-color: #ff9b30 !important;
}

.cotizacionButtonContainer{
    padding-top: 20px;
}


