.contactDialogListItem{
    border-radius: 0px !important;
    width: 100%;
    font-family: machineFont !important;
    font-size: 25px !important;
    margin: 0 !important;
    border-color: #000000 !important;
    border-width: 1px !important;
    border-style: solid !important;
    color: #ff9b30 !important;
    background-color: #000000 !important;
}