.searchMachineWrapper {
    width: 100%;
    position: relative;
}

.searchMachineRoot {
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 1fr;
    border: solid 1px #000000;
    border-radius: 18px;
}

.searchMachineList {
    width: 100%;
    position: absolute;
    z-index: 1;
    overflow: auto;
    max-height: 24rem;
    background-color: #000000;
}

.searchMachineInput {
    height: 40px;
    font-size: 18px;
    border-radius: 18px;
    border-color: transparent;
    outline: none;
    padding: 0px 1rem;
}

.searchButton {
    background-color: #000000;
    border-radius: 0px 18px 18px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchButton:hover {
    cursor: pointer;
}

.searchButtonIcon {
    font-size: 2.5rem !important;
    color: #ffffff;
}

.searchMachineLoadMoreIcon {
    font-size: 2.5rem !important;
    color: #ffffff;
}

.searchMachineLoadMoreButton {
    font-size: 1.5rem !important;
    font-weight: bolder !important;
}

.searchMachineOnCloseButton {
    background-color: #f89b01;
    float: right;
    border-radius: 50%;
    display: flex;
    align-items: center;
    align-content: center;
}

.searchMachineInput::placeholder {
    font-size: 0.9rem;
}

.searchMachineDetailsTitle {
    padding: 10px 0px;
    width: 100%;
    background-color: #000000;
    color: #ff9b01;
    text-align: center;
    font-size: 1rem;
    font-weight: lighter;
    cursor: pointer;
}

.searchMachineImages {
    justify-content: center;
}

.searchMachineDetails {
    padding: 0.5rem;
    background-color: #ff9b01;
    cursor: pointer;
}

.searchMachinePriceTextBold {
    font-weight: bold;
    font-size: 0.9rem;
    color: gray;
}

.searchMachineData > div {
    font-size: 0.6rem;
}

.searchMachineInfoContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #000000;
}

@media screen and (max-width: 960px) { 
    .searchMachineRoot {
        width: 50%;
        margin: auto;
    }

    .searchMachineList {
        width: auto;
        margin: 0rem 25%;

    }
}

@media screen and (max-width: 600px) { 
    .searchMachineRoot {
        width: 80%;
        margin: auto;
    }

    .searchMachineList {
        width: auto;
        margin: 0rem 10%;
    }
}