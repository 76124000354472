.machines-search-box {
    position: relative;
    border-radius: 2px;
    margin: auto !important;
    width: 100% !important;
    border: solid 2px #ff9b01;
    margin-top: 20px !important;
}
  
.machines-search-input-root {
    width: 100%;
    margin: 0px 10px;
}

.root {
    flex-grow: 1;
    background-color: #ff9b01;
}

.changeStatusButtonContainer > button {
    height: 55px;
}

.module-main-container-admin-machines {
    padding-top: 1rem;
}
