.card-container{
    height: 100% !important;
    position: relative !important;
}

.card-link{
    text-decoration: none;
}

.card-media{
    min-height: 400px;
    background-size: 100% 100% !important;
}

.card-title{
    background-color: #ff9b01;
    text-transform: uppercase;
    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-weight: bold;
}

.card-services-text{
    font-family: 'Calibri', sans-serif !important;
    color: #000 !important;
}

.card-show-more{
    height: 50px;
    padding: 15px 10px !important;
}

.card-show-more-button{
    color: black !important;
    font-weight: bold !important;
    font-size: 15px !important;
    border-radius: 0 !important;
    background-color: #ff9b01 !important;
    position: absolute !important;
    bottom: 10px;
}

.card-show-more-button:hover{
    background-color: #ff9b01 !important;
    font-weight: bold;
    text-decoration: underline !important;
}

.card-action-area{
    height: 100%;
    display: contents !important;
}