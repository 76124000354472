.mainLandPage{
    /*background-color: red;*/
    background: url('../../images/mainBanner.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    /*position: absolute;*/
    /*top: 64px;*/
    bottom: 0;
    right: 0;
    left: 0;


}

.leftPanel{
    background-color: #ff9b30de;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightPanel{
    background-color: #1d1d1df0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leftPanelCenter{
    border-style: solid;
    border-color: black;
    border-width: 3px;
    padding: 20px;
    margin: 60px;
}

.rightPanelCenter{
    border-style: solid;
    border-color: #ff9b30;
    border-width:3px;
    padding: 20px;
    margin: 60px;
    color: #ff9b30;
}

.leftPanelTitle{
    /*text-align: center;*/
    letter-spacing: 2px;
    font-family: machineFont;
}

.leftPanelText{
    line-height: 25px;
    letter-spacing: 2px;
    text-align: justify;
    font-family: machineFont;
}

.rightPanelTitle{
    letter-spacing: 2px;
    font-family: machineFont;
}

.button-link{
    text-decoration: none !important;
}

.expertise-panel{
    font-family: 'Calibri', sans-serif;
    padding: 10px;
    text-align: justify;
}

.inspected-seal-img-container{
    font-family: 'Calibri', sans-serif;
    padding: 40px;
    text-align: center;
}

.inspected-seal-img-panel{
    height: auto;
    width: 70%;
}

.inspected-seal-img{
    height: auto;
    width: 100%;
}

.map-img{
    height: auto;
    width: 50%;
}

.expertise-panel-options{
    padding: 40px;
    text-align: justify;
}

.expertise-panel-option-selected{
    color: #ff9b30 !important;
}