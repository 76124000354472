.TMPSimpleCard-root {
    background-color: #000000;
    color: #ffffff;
    height: 6rem;
    border-radius: 1rem;
    padding: 1rem;
    display: grid;
    align-content: center;
}

.TMPSimpleCard-title { 
    text-transform: capitalize;
    font-size: 1rem;
}

.TMPSimpleCard-value { 
    font-size: 1.5rem;
}