.mainContainer {
    justify-content: center;
    text-align: center;
}

.mainRow {
    max-width: 960px;
    margin: 10px;
    padding: 10px;
}

.mainPaper{
    border-radius: 0;
    text-align: center;
    border-radius: 0px !important;
}

.menu{
    width: 300px;
}

.contact-us-paragraph-title{
    font-weight: bold;
    font-family: 'Calibri', sans-serif !important;
    text-align: left;
    font-size: 20px;
    font-style: italic;
}

.contact-us-paragraph-subtitle{
    font-family: 'Calibri', sans-serif !important;
    text-align: left;
    font-size: 20px;
}

.svgButton{
    height: 35px;
}

.map-img-container{
    width: 100%;
    margin: auto;
    text-align: center;
}

.services-card-link{
    text-decoration: none;
}

.services-card-link button:hover{
    background-color: #ebebeb;
}

.contact-us-banner-img{
    width: 100%;
    height: auto;
}

.tmp-circular-progress{
    color: black !important;
    padding: 0px 10px !important;
}

.about-us-panel-container{
    padding: 20px !important;
}

.sell-rent-galley-img-btn{
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #ff9b01;
}

.card-product-action-button{
    text-align: center;
    color: red;
}

.card-product-content{
    background-color: #ff9b01;
}

.galleryPriceStyle{
    font-size: 25px !important;
    font-weight: lighter !important;
}

a {
    text-decoration: none; /* no underline */
}

.filterList{
    padding: 20px !important;
}

.sellRentGalleryItemFullWidth{
    width: 100%;
}

.sellRentHeaderPostButton{
    padding: 0px 24px;
}

.svgButtonSellRentHeaderPostButton{
    border-radius: 0px !important;
    width: 100%;
    background-color: #ff9b30 !important;
}

.svgButtonSellRentHeaderPostButton:hover{
    text-decoration: underline !important;
}

.svgButtonSellRentHeaderPostButtonIcon{
    margin: 0 10px 0 0;
}

.panelButton{
    border-radius: 0px !important;
    /*height: 50px;
    margin-bottom: 20px !important;*/
    width: 100%;
    background-color: #ff9b30 !important;
    font-family: machineFont !important;
    font-size: 25px !important;
}

.sellRentPricingCard{
    height: 100%;
    border-radius: 0px !important;
}

.sellRentPricingCardHeader{
    background-color: #ff9b30;
}

.sellRentPricingCardPricing{
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.sellRentPricingItems{
    color: gray !important;
    padding: 10px;
    font-weight: bold;
}

.sellRentPricingItemsIcon{
    font-size: 16px !important;
    color: #000000;
}

.sellRentStepPost svg{
    color: #000000 !important;
}

.module-main-container {
    width: 90% !important;
    margin: auto !important;
}

/** BOOTSTRAP TABLE STYLES **/

.bootstrap-table-wrapper{
    width: 100%;
    border: solid 1px #54acbb;
}
  
.bootstrap-table{
    width: 100%;
    border-spacing: 0px;
}

.bootstrap-table tr:hover{
    background-color: #000000 !important;
    color: #fff !important;
}

.bootstrap-table-header{
    height: 35px;
    color: #000000;
    text-align: center;
    cursor: pointer;
    font-weight: bold !important;
}

.btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.btn-default {
    color: #54a7b6 !important;
    cursor: default;
    background-color: #ffffff;
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    text-decoration: none;
    border: 1px solid #54a7b6;
}

.dropdown-menu {
    position: absolute;
    /*top: 100%;
    left: 0;*/
    z-index: 1000;
    display: none;
    float: left;
    min-width: 45px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.show{
    display: block !important;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
}

.pagination > li {
    display: inline;
}

.react-bootstrap-table-page-btns-ul {
    float: right;
    margin-top: 0px;
}

.react-bootstrap-table th.sortable {
    cursor: pointer;
    /*white-space: nowrap !important;*/
}

.react-bootstrap-table{
    text-align: center;
    font-size: 12px !important;
}
  
.page-item{
    color: #54a7b6 !important;
}
  
.page-link{
    color: #54a7b6 !important;
    cursor: default;
    background-color: #ffffff;
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    text-decoration: none;
    border: 1px solid #54a7b6;
}
  
.dropdown-item {
    color: #54a7b6 !important;
    cursor: default;
    background-color: #ffffff;
    /*padding: 6px 12px;*/
    margin-left: -1px;
    line-height: 1.42857143;
    text-decoration: none;
    border: 1px solid #54a7b6;
}
  
.dropdown-item a {
    color: #54a7b6 !important;
    padding: 6px 12px;
    width: 100%;
}

.tabs-root {
    flex-grow: 1;
    background-color: #ff9b01;
}

.tabs-tabs-root {
    border-bottom: 1px solid #000000;
}

.tabs-indicator {
    background-color: #000000 !important;
    height: 4px !important;
}

.tab-root {
    text-transform: initial;
    min-width: 72px !important;
    font-size: 18px !important;
}

.tab-root :hover {
    color: #000000;
    opacity: 5;
}

.tab-root :focus {
    color: #000000;
}

.tab-selected {
    background-color: #54acbb;
}

.tabs-root {
    border-bottom: 1px solid #000000;
}

.tabs-indicator {
    background-color: #ffffff;
    height: "4px",
}

.tab-root {
    text-transform: initial;
    min-width: 72px;
    font-weight: 10px;
    font-size: 14px;
}

.tab-root span {
    font-size: 14px;
}

.tab-root span:hover {
    font-weight: bolder;
}

.tab-root span:focus {
    font-weight: bolder;
}

.tab-root:hover {
    opacity: 5;
}

.tab-root:focus {
    color: #000000;
}

.tab-selected span {
    font-size: 14px;
}

.TMPModalContainer{
    display: flex;
    align-items: center;
    overflow: auto;
}
  
.TMPModalContent{
    width: 80%;
    margin: auto;
    background-color: whitesmoke;
    padding: 30px;
    border-radius: 4px;
}

.TMPModalTitle {
    text-align: center;
}

.TMPSelect {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;
}

@media screen and (max-width: 768px) {
    .module-main-container {
        width: 90% !important;
    }
}

@media screen and (max-width: 480px) {
    .module-main-container {
        width: 100% !important;
    }
}

@media (min-width: 992px){
    .col-md-6 {
        width: 50%;
        float: left;
        min-height: 25px;
    }
}

@media screen and (max-width: 992px){
    .react-bootstrap-table{
        font-size: 10px !important;
    }
}
  
@media screen and (max-width: 600px){
    .react-bootstrap-table{
        font-size: 5px !important;
    }
}