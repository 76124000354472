.HomeComponentCardIcons{
    width: 100% !important;
    margin: auto !important;
    font-size: 100px !important;
}

.HomeComponentCardTitle{
    width: 100% !important;
    margin: auto !important;
    text-align: center !important;
}

.HomeComponentCardContainer{
    padding: 30px;
}

.HomeComponentCardList{
    width: 100% !important;
}

.HomeComponentCardExpansion{
    padding: 0 !important;
}

.HomeComponentCardListLink{
    text-decoration: none;
    color: #000000;
}

.admin-card-content-root {
    width: 100%;
}