.insurance-component-carousel-container{
    width: 100%;
    overflow: hidden;
}

.insurance-component-carousel-container img{
    width: 100% !important;
    overflow: hidden;
}

.insurance-component-card-container{
    padding: 20px;
}