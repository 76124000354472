.add-provider-button{
    margin-top: 14px !important;
    height: 59px;
    border-radius: 5px !important;
}

.chips-style{
    border-radius: 4px !important;
    margin: 0px 10px !important;
}

.chip-container{
    border: solid 1px #ba8623 !important;
    border-radius: 4px !important;
    margin: 12px !important;
    padding: 12px 0px !important;
}