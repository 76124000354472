.grow {
    flex-grow: 1;
    text-decoration: none !important;
}

.grow:hover {
    flex-grow: 1;
    color: #ffffff !important;
    text-decoration: underline !important;
}

.mainHeader{
    background-color: #000000 !important;
    position: unset !important;
}

.loginButton{
    font-family: BebasFont, serif !important;
    border-radius: 0 !important;
}

.header-home-icon{
    color: #ffffff;
}

.grow-button{
    width: 100%;
}

.grow-button-head{
    width: 100%;
}

.grow-button span{
    font-family: BebasFont, serif !important;
    font-size: 25px;
    border-radius: 0 !important;
    color: #ffffff;
    text-decoration: none !important;
}

.grow-button-head span{
    font-family: BebasFont, serif !important;
    font-size: 15px;
    border-radius: 0 !important;
    color: #ffffff;
    text-decoration: none !important;
    letter-spacing: 0.5px;
}

.grow-button-head-yellow span{
    font-family: BebasFont, serif !important;
    font-size: 15px;
    border-radius: 0 !important;
    color: #ff9b01;
    text-decoration: none !important;
    letter-spacing: 0.5px;
    min-width: 130px;
}

.header-social-icon{
    height: 40px;
    margin: 10px;
}

.header-middle-left-panel{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.header-middle-right-panel{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header-header-left-panel{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.header-header-left-panel-item{
    display: flex;
}

.header-header-item-left{
    padding: 0px 10px;
    border-right: solid 1px white;
    margin: 5px 0px;
}

.header-header-item-right{
    margin: 5px 0px;
}

.header-middle{
    background-color: white;
    color: #000000;
}

.header-logo{
    height: 120px;
    padding: 15px 50px;
    /*margin: auto;*/
}

.main-toolbar-header{
    min-height: unset !important;
    height: 50px !important;
}

.services-text{
    font-family: 'Calibri', sans-serif !important;
    color: #000 !important;
}

.services-text-italic{
    /*font-style: italic;*/
}

.home-card-full-height{
    height: 100% !important;
    position: relative !important;
}

.services-show-more{
    color: black !important;
    font-weight: bold !important;
    font-size: 15px !important;
    border-radius: 0 !important;
    background-color: #ff9b01 !important;
    position: absolute !important;
    bottom: 10px;
}

.services-show-more-container{
    height: 50px;
    padding: 15px 10px !important;
}

.services-show-more:hover{
    background-color: #ff9b01 !important;
    font-weight: bold;
    text-decoration: underline !important;
}

.user-top-main-menu{
    border: solid 1px #000000;
}

.user-top-main-menu-item{
    font-family: BebasFont, serif !important;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #000000;
    padding: 5px;
}

.user-top-main-menu-item:hover{
    background-color: #ff9b01;
    cursor: pointer;
}

.user-top-main-menu-item span{
    padding: 0px 10px;
}

.header-middle-center-panel {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .grow-button span{
        font-size: 13px !important;
    }

    .grow-button-head-yellow span{
        font-size: 13px !important;
    }

    .grow-button-head span{
        font-size: 13px !important;
    }

    .header-social-icon{
        height: 30px !important;
    }

    .header-middle-left-panel{
        justify-content: center !important;
    }

    .header-middle-right-panel{
        justify-content: center !important;
    }
}