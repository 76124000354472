.home-card-media{
    height: 300px;
    background-size: 100% 100% !important;
}

.home-carousel-card-container{
    width: 100%;
    overflow: hidden;
}

.home-carousel-card-container img{
    width: 100% !important;
    overflow: hidden;
}

.home-card{
    padding: 20px !important;
}