
.MyProfile-paper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.MyProfile-error {
    color: #ef2020;
}

.MyProfile-success {
    color: #28a745;
}

.MyProfile-progress {
    color: #ff9b01;
}

.MyProfile-submit {
    min-height: 56px;
    color: #000000;
    border-radius: 4px !important;
}

.MyProfile-link {
    text-decoration: none;
    color: #000000 !important;
}

.MyProfile-link:hover {
    text-decoration: underline;
    color: #000000 !important;
}

.MyProfile-item input {
    color: #000000 !important;
}