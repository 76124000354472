.paper {
    margin: 10% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.logoContainer {
    text-align: center;
}

.logo {
    max-height: 100%;
    max-width: 50%;
}

.error {
    color: #ef2020,
}

.progress {
    color: #ff9b01;
}

.submit {
    min-height: 56px;
    color: #000000;
    border-radius: 4px !important;
}

.link {
    text-decoration: none;
    color: #000000 !important;
}

.link:hover {
    text-decoration: underline;
    color: #000000 !important;
}

.link2 {
    text-decoration: none;
    color: #ffffff !important;
}

.link2:hover {
    text-decoration: underline;
    color: #ffffff !important;
}

.loginType {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}