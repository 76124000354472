.subtitle {
  font-family: BebasFont, serif !important;
  padding-top: 20px;
  width: 100%;
  text-align: left;
  font-size: 2rem;
}

.ribbonContainerDisable {
  position: relative;
  overflow: hidden;
  cursor: not-allowed;
}

.ribbonContainer {
  position: relative;
  overflow: hidden;
}

.cornerRibbon {
  color: #000000;
  background: #ff9b01;
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 22px;
  text-align: center;
  transform: translateY(-100%) rotate(90deg) translateX(70.71067811865476%) rotate(-45deg);
  transform-origin: bottom right;
  line-height: 33px;
}

.cardHeader {
  background-color: #ff9b01;
  min-height: 100px;
}

.cardHeaderDisabled {
  background-color: #5a5a5a;
  min-height: 100px;
  color: #fff;
}

.cardPricing {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.cardItems {
  font-size: 12px;
  border-top: 1px dashed;
  padding: 5px;
  text-align: justify;
}

.checkIcon {
  color: #28a745;
  height: 20px;
  width: 20px;
  margin: 0px 5px;
}

.closeIcon {
  color: #dc3545;
  height: 20px;
  width: 20px;
  margin: 0px 5px;
}