.WhatsappFloatButton-root {
    position: fixed;
    right: 20px;
    top: 56%;
    background-color: #25d366;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    z-index: 9999;
}

.WhatsappFloatButton-icon {
    height: 60px;
    width: 60px;
}

@media screen and (max-height: 600px){
    .WhatsappFloatButton-root {
        top: 59%;
    }   
}

@media screen and (max-height: 700px){
    .WhatsappFloatButton-root {
        top: 58%;
    }   
}

@media screen and (max-height: 800px){
    .WhatsappFloatButton-root {
        top: 57;
    }   
}

